import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import MenuPopover from '../../../components/menu-popover';
import { useSnackbar } from '../../../components/snackbar';
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------
export default function AccountPopover() {
    const { user, logout } = useAuthContext();
    const theme = useTheme();
    const hasSuperAdminRole = user?.roles.find((role) => role?.code === 'SUPER_ADMIN');
    const currentRole = hasSuperAdminRole ? 'SUPER_ADMIN' : user?.roles[0].code;
    const OPTIONS = [
        {
            label: 'Profile',
            linkTo: currentRole === 'PROTHESIST' ? PATH_DASHBOARD.profileLabo : PATH_DASHBOARD.profileCabinet,
        },
    ];
    // ----------------------------------------------------------------------
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [openPopover, setOpenPopover] = useState(null);
    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };
    const handleClosePopover = () => {
        setOpenPopover(null);
    };
    const handleLogout = async () => {
        try {
            await logout();
            navigate(PATH_AUTH.login, { replace: true });
            handleClosePopover();
        }
        catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout!', { variant: 'error' });
        }
    };
    const handleClickItem = (path) => {
        handleClosePopover();
        navigate(path);
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { onClick: handleOpenPopover, sx: {
                    p: 0,
                    ...(openPopover && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: 'unset',
                            position: 'absolute',
                        },
                    }),
                }, children: _jsxs(Stack, { sx: { borderRadius: '79px', background: '#F1F7FF' }, direction: "row", alignItems: "center", justifyContent: "space-between", p: 1.4, spacing: 1, children: [_jsx(CustomAvatar, { src: user?.avatar, alt: `${user?.name} ${user?.lastName}`, name: `${user?.name} ${user?.lastName}`, sx: { background: theme.palette.primary.main } }), _jsx(Typography, { variant: "subtitle2", noWrap: true, sx: { textTransform: 'capitalize' }, children: `${user?.name} ${user?.lastName}` }), _jsx(Iconify, { sx: openPopover ? { transform: 'rotate(180deg)' } : {}, icon: "fluent:chevron-down-24-filled" })] }) }), _jsxs(MenuPopover, { open: openPopover, onClose: handleClosePopover, sx: { width: 200, p: 0 }, children: [_jsxs(Box, { sx: { my: 1.5, px: 2.5 }, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, sx: { textTransform: 'capitalize' }, children: `${user?.name} ${user?.lastName}` }), _jsx(Typography, { variant: "body2", sx: { color: 'text.secondary' }, noWrap: true, children: user?.email })] }), !hasSuperAdminRole && _jsx(Divider, { sx: { borderStyle: 'dashed' } }), !hasSuperAdminRole && (_jsx(Stack, { sx: { p: 1 }, children: OPTIONS.map((option) => (_jsx(MenuItem, { onClick: () => handleClickItem(option.linkTo), children: option.label }, option.label))) })), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(MenuItem, { onClick: handleLogout, sx: { m: 1 }, children: "Logout" })] })] }));
}
